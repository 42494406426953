<template>
  <b-overlay :show="loading">
    <AuthWrapper>
      <template #heading>
        <SecurityLogo />
      </template>

      <template #card>
        <div class="">
          <div class="text-center">
            <h1>Recover MFA </h1>
          </div>
          <div class="mb-50">
            <p class="m-0 p-0 text-center">
              Kindly enter all 10 of the recovery codes sent to your email
              <br />
              <small class="m-0 p-0 text-center">
                This will delete MFA from your account
              </small>
            </p>
          </div>
        </div>

        <div class="">
          <validation-observer ref="authenticateForm" #default="{invalid}">
            <b-form @submit.prevent="verify">

              <div class="">
                <div v-if="codes.length <= maxContent" class="flex-fill">
                    <div v-for="(code, i) in codes" :key="i">
                      <!--                recovery code -->
                      <div>
                        <div class="align-items-center">
                          <div class="d-flex align-items-start">
                            <p class="mr-1 lead font-weight-bolder">
                              {{ i + 1 }}
                            </p>

                            <b-form-group class="flex-fill mb-1" label-for="code">
                              <validation-provider
                                  #default="{ errors }"
                                  name="Code"
                                  vid="code"
                                  rules="required|length:7"
                              >
                                <div class="exxtra-input">
                          <span class="leading">
                        <PasswordLock />
                      </span>
                                  <input id="login-username" v-model="codes[i]"
                                         minlength="7"
                                         maxlength="7"
                                         type="number" class="otp"
                                         placeholder="* * * * *"
                                  >
                                </div>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </div>
                        </div>
                      </div>

                    </div>
                </div>
              </div>

              <div>
                <b-button
                    class="py-1"
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                >
                  Recover Account
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          <div class="mt-2">
            <p class="text-center text-sm cursor-pointer" @click="logUserOut">
              <feather-icon icon="LogOutIcon" />
              Logout
            </p>
          </div>
        </div>
      </template>

    </AuthWrapper>

  </b-overlay>
</template>

<script>
import { get } from "lodash";
import PasswordLock from "@/assets/svg/PasswordLock.svg";
import { required, length } from "@core/utils/validations/validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import SecurityLogo from "@/pages/auth/shared-components/SecurityLogo.vue";
import { BOverlay, BLink, BButton, BForm, BFormGroup } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "MfaRecovery",
  components: {
    AuthWrapper,
    SecurityLogo,
    PasswordLock,
    BOverlay,
    BLink,
    BForm,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      codes: ['', '', '', '', '', '', '', '', '', ''],
      maxContent: 10,
      showInput: false,
      mfaMethod: '',
      length,
      required
    }
  },
  created() {
    this.retrieveMfaMethod();
  },
  methods: {
    async verify() {
      try {
        const payload = {
          recovery_codes: this.codes
        }
        await this.useJwt()
            .mfaService
            .recoverMFAAccount(payload);

        // const response_data = response.data.data;

        this.useJwt().mfaService.clearMfaAccessToken();
        this.$router.replace({
          name: 'security-mfa-recovery-success'
        })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Wonderful`,
                  icon: 'EditIcon',
                  variant: 'success',
                  text: `You have successfully removed MFA on this account.`,
                },
              })
            })
            .catch(() => {
            });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });

        if (error_message === "Invalid access token.") {
          await this.logUserOut();
        }
      } finally {
        this.loading = false;
      }
    },
    async logUserOut() {
      this.useJwt()
          .mfaService
          .clearMfaAccessToken();
      await this.$router.push({ name: 'auth-login' })
    },
    retrieveMfaMethod() {
      const { mfa_method } = this.$route.params;
      this.mfaMethod = mfa_method
    },
    addCode(){
      this.codes.push('');

      if (this.codes.length > this.maxContent) {
        this.codes.pop();
      }
    },
    deleteCode(code) {
      this.codes.splice(code, 1)
    },
  }
}
</script>

<style scoped>

</style>
